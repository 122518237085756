<template>
  <setup
    title="Reset Your Password"
    :error="error"
    :hidePageTitle="hidePageTitle">
    <div class="otp-sending-progress" v-cloak v-if="sendingOtp">
      <div>
        <span>Sending your code</span>
      </div>
    </div>
    <div class="otp-sending-progress" v-cloak v-if="verifyingOtp">
      <div>
        Checking that code.<span style="white-space: nowrap"
          >Please wait a moment...</span
        >
      </div>
    </div>
    <form class="card-body" method="post" @submit.prevent="handleFormSubmit">
      <div
        class="default-form w-border"
        style="text-align: center; margin: auto">
        <p class="p-activation-note">
          <strong>To confirm your identity, </strong> enter the code sent to<br />
          <span>{{ otpToEmail ? email : mobileNumber }}</span>
        </p>

        <div class="form-row">
          <div class="otp-control">
            <v-otp-input
              ref="otpInput"
              input-classes="otp-input"
              separator=""
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="false"
              @on-change="handleOtpChange"
              @on-complete="handleOtpComplete" />
          </div>
        </div>
        <div class="form-row">
          <div class="spinner small light" v-show="verifyingOtp"></div>
          <input
            type="submit"
            value="VERIFY"
            class="button large blue"
            v-show="!verifyingOtp"
            v-cloak
            ref="submitBtn" />
        </div>
        <div class="form-row" style="line-height: 2em">
          <a style="cursor: pointer" v-on:click="sendOtp">Resend the code</a>
          <br />
          <span v-cloak v-if="email && !otpToEmail"
            ><a style="cursor: pointer" v-on:click="handleSwitchOtpReceiver"
              >Send the code ({{ email }})</a
            >
            <br
          /></span>

          <span v-cloak v-if="mobileNumber && otpToEmail"
            ><a style="cursor: pointer" v-on:click="handleSwitchOtpReceiver"
              >Send the code ({{ mobileNumber }})</a
            >
            <br
          /></span>
          <a style="cursor: pointer" v-on:click="handleGoToQuestions"
            >Take me to my secret questions</a
          >
        </div>
      </div>
    </form>
  </setup>
</template>

<script>
  import Setup from "./Setup.vue";
  import {getErrorMessage} from "../remote/ajax";
  export default {
    name: "OTP",
    props: ["sentToEmail", "email", "mobileNumber", "redirectUrl", "token"],
    data() {
      return {
        otpToEmail: this.$props.sentToEmail,
        sendingOtp: false,
        verifyingOtp: false,
        code: "",
        error: "",
        hidePageTitle: false,
      };
    },
    methods: {
      handleFormSubmit() {
        if (!this.code || this.code.length < 6) {
          this.error = `Insert the confirmation code that was sent to your
  				${
            this.otpToEmail
              ? "email address (" + this.email + ")"
              : "contact number (" + this.mobileNumber + ")"
          }`;
          return false;
        }

        this.error = "";
        const url = this.otpToEmail
          ? "/Password/VerifyEmailOTP"
          : "/Password/VerifySMSOTP";
        this.verifyingOtp = true;
        this.$ajax
          .post(
            url,
            {
              verificationCode: this.code,
            },
            {
              headers: {
                Authorization: this.token,
              },
            },
          )
          .then((resp) => {
            this.verifyingOtp = false;
            const error = getErrorMessage(resp.data.result, "VERIFY_OTP");
            if (error === "") {
              this.$router.push({
                name: "SetNew",
                params: {redirectUrl: this.redirectUrl},
                query: {token: resp.data.token},
              });
            } else {
              this.error = error;
              this.$refs.otpInput.clearInput();
            }
          })
          .catch((err) => {
            this.verifyingOtp = false;
            if (err.status == 401) {
              this.redirectTo(
                "That verification code has expired. Redirecting you to the Reset Pasword page...",
              );
            }
          });
      },
      handleOtpChange(value) {
        this.code = value;
      },
      handleOtpComplete(value) {
        this.code = value;
        setTimeout(() => {
          this.$refs.submitBtn.focus();
        }, 10);
      },
      sendOtp() {
        const url = this.otpToEmail
          ? "/Password/SendOTPToEmail"
          : "/Password/SendOTPToSMS";
        this.sendingOtp = true;
        this.$ajax
          .get(url, {
            headers: {
              Authorization: this.token,
            },
          })
          .then((resp) => {
            const error = getErrorMessage(resp.result, "SEND_OTP");
            if (error) {
              this.error = error;
            }
          })
          .catch((error) => {
            const __this = this;
            if (error.result === "UNAUTHORIZED" || error.status === 401) {
              this.redirectTo(
                "The browser session has expired. Redirecting you to the Reset Pasword page...",
              );
            } else if (error.result === "SERVER_ERROR") {
              this.error = getErrorMessage(error.result);
            }
          })
          .finally(() => {
            this.sendingOtp = false;
          });
      },
      handleSwitchOtpReceiver() {
        this.otpToEmail = !this.otpToEmail;
        this.sendOtp();
      },
      handleGoToQuestions() {
        this.$router.push({
          name: "VerifyAnswer",
          params: {
            redirectUrl: this.redirectUrl,
            email: this.email,
            sentToEmail: this.sentToEmail,
            mobileNumber: this.mobileNumber,
            token: this.token,
          },
        });
      },
      redirectTo(message, page) {
        const router = this.$router;
        const name = page || "Recover";
        if (message) this.error = message;
        setTimeout(() => {
          router.push({
            name,
            query: {redirectUrl: this.redirectUrl || undefined},
          });
        }, 3000);
      },
    },
    components: {
      Setup,
    },
    mounted() {
      if (!this.token) {
        this.$router.push({
          name: "Recover",
          query: {redirectUrl: this.$route.query.redirectUrl},
        });
      } else {
        this.sendOtp();
      }
    },
  };
</script>
