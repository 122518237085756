<script>
    import Vue from "vue";
    let PasswordPage = Vue.extend({
        name: "PasswordPage",
        props: ["passwordVal", "confirmPasswordVal", "loading"],
        data() {
            return {
                output: {
                    password: "",
                    confirmPassword: "",
                },
            };
        },
        watch: {
            "output.password": function () {
                this.$emit("input", this.output);
            },
            "output.confirmPassword": function () {
                this.$emit("input", this.output);
            },
        },
    });
    Vue.component("passwordPage", PasswordPage);
    export default PasswordPage;
</script>

<style scoped>
    .newui .section .default-form .form-row input[type="password"].long {
        max-width: 100%;
    }
</style>

<template>
    <div class="default-form w-border" style="text-align: left; margin: auto">
        <div class="form-row">
            <input type="password" placeholder="Enter your new password" v-model="output.password" class="long" />
        </div>
        <div class="form-row">
            <input type="password" placeholder="Re-enter your new password" v-model="output.confirmPassword" class="long" />
        </div>
        <div class="form-row" style="text-align: center">
            <div class="spinner small light" v-show="loading"></div>
            <input type="submit" value="Continue" class="button large blue" v-show="!loading" v-cloak />
        </div>
    </div>
</template>
