<template>
    <setup title="Set Up Your Email" :error="error">
        <form method="post" class="card-body" @submit.prevent="handleFormSubmit">
            <div class="default-form w-border" style="text-align: left; margin: auto">
                <div class="mfa-loader-wrap" v-show="mfaLoading">
                    <div class="mfa-loader spinner small light"></div>
                </div>
                <div class="form-row">
                    <input
                        type="text"
                        v-bind:disabled="mfaLoading"
                        placeholder="Enter Work Email (Optional)"
                        v-model="WorkEmail"
                        class="long" />
                </div>
                <div class="form-row">
                    <input
                        type="text"
                        v-bind:disabled="mfaLoading"
                        :placeholder="`Enter Personal Email ${personalEmailRequired ? '(Required)' : '(Optional)'}`"
                        v-model="HomeEmail"
                        class="long" />
                </div>
                <div class="form-row" style="text-align: center">
                    <div class="spinner small light" v-show="loading"></div>
                    <input
                        type="submit"
                        v-bind:disabled="mfaLoading"
                        value="Continue"
                        class="button large blue"
                        v-show="!loading"
                        v-cloak />
                </div>
            </div>
        </form>
    </setup>
</template>

<style scoped>
    .mfa-loader-wrap {
        text-align: center;
    }
    .form-row > input:disabled {
        visibility: hidden;
    }
</style>

<script>
    import Setup from "./Setup.vue";

    export default {
        name: "Emails",
        components: {
            Setup,
        },
        data() {
            return {
                error: "",
                loading: false,
                redirectUrl: "",
                WorkEmail: "",
                HomeEmail: "",
                personalEmailRequired: false,
                requiresMfa: undefined,
                mfaLoading: false,
                useEmailsOnly: false,
            };
        },
        methods: {
            async checkCompanyMfa() {
                this.mfaLoading = true;
                const res = await this.$ajax.get(`${this.$apiUrl}/api/Setup/company-check`, {withCredentials: true});
                const {requiresMfa, contactDetailsAvailable, useEmailsOnly} = res.data;
                this.requiresMfa = requiresMfa;
                this.useEmailsOnly = useEmailsOnly;
                this.personalEmailRequired = requiresMfa && (!contactDetailsAvailable || useEmailsOnly);
                this.mfaLoading = false;
            },
            handleFormSubmit() {
                let data = {
                    redirectUrl: this.redirectUrl,
                    HomeEmail: this.HomeEmail,
                    WorkEmail: this.WorkEmail,
                    requiresMfa: this.requiresMfa,
                    IsSetupRequired: this.useEmailsOnly,
                };
                this.error = "";

                if (this.personalEmailRequired && !data.HomeEmail) {
                    this.error = "Personal email is required.";
                } else if (data.WorkEmail && !this.$validateEmail(data.WorkEmail)) {
                    this.error = "Work email is not valid.";
                } else if (data.HomeEmail && !this.$validateEmail(data.HomeEmail)) {
                    this.error = "Personal email is not valid.";
                }

                if (this.error != "") return;

                this.loading = true;

                this.$ajax
                    .post(`/Setup/Emails`, data)
                    .then(async (resp) => {
                        if (typeof resp.data != "undefined") {
                            const {
                                Error,
                                ClientRedirect,
                                RedirectUrl: redirectUrl,
                                RedirectPage,
                                EssRedirect,
                            } = resp.data;
                            if (Error != null) {
                                this.error = resp.data.Error;
                            } else if (ClientRedirect && redirectUrl.length) {
                                let channels, mfaNotSet;
                                if (!EssRedirect) {
                                    const {authenticated, mfaRequired, otpChannels} = await this.$checkMFACookie(
                                        redirectUrl,
                                    );
                                    mfaNotSet = mfaRequired && !authenticated;
                                    channels = otpChannels;
                                }
                                if (mfaNotSet) {
                                    this.$router.push({
                                        name: "MFA",
                                        params: {
                                            redirectUrl,
                                            otpChannels: channels,
                                        },
                                    });
                                } else {
                                    if (!EssRedirect)
                                        await this.$ajax.post("/legacy/success", null, {withCredentials: true});
                                    window.location.href = redirectUrl;
                                }
                            } else if (RedirectPage != null) {
                                const queryString = redirectUrl != null ? {redirectUrl} : {};
                                this.$router.push({
                                    name: RedirectPage,
                                    query: queryString,
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        if (err.status == 401) {
                            this.$router.push({
                                name: "Login",
                                query: {redirectUrl: this.redirectUrl || undefined},
                            });
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
        },

        mounted() {
            if (typeof this.$route.query != "undefined" && typeof this.$route.query.redirectUrl != "undefined") {
                this.redirectUrl = this.$route.query.redirectUrl;
            }

            this.checkCompanyMfa();
        },
    };
</script>
