<template>
	<div>
		<div class="login-underlay" id="login-underlay">
			<Header />
			<Footer />
		</div>
		<div class="login">
			<div class="login-box-bg hidden" id="login-box-bg"></div>
			<div class="login-box-wrapper">
				<div class="login-box smaller" id="login-box">
					<div class="box-title">Login</div>
					<div class="box-body">
						<div class="errormsg" v-if="this.error" v-html="this.error"></div>
						<form @submit.prevent="handleFormSubmit">
							<fieldset>
								<div class="errormsg hidden">We could not log you in</div>
								<div class="login-box-row label">
									<input
										type="text"
										id="username"
										name="username"
										ref="userName"
										autocomplete="off"
										placeholder="Username or email"
										v-on:change="checkEmail" />
									<a class="username-question" v-tooltip="unHelpTooltip">
										<span class="icon-help-round"></span>
									</a>
								</div>
								<div class="login-box-row label" v-if="showEmailUsers" v-cloak>
									<div class="select">
										<select id="emailuser" v-model="emailUser" name="emailuser">
											<option value="null" disabled selected>Username and company</option>
											<option
												v-for="user in availableEmailUsers"
												v-bind:value="user.UserName"
												:key="user.UserName">
												{{ user.UserName }}, {{ user.CompanyName }} ({{ user.CompanyNumber }})
											</option>
										</select>
									</div>
								</div>
								<div class="login-box-row label">
									<input
										type="password"
										id="password"
										name="password"
										ref="password"
										autocomplete="off"
										placeholder="Password" />
								</div>
								<div class="login-box-row buttons">
									<div class="loader" v-show="loading"></div>
									<input
										type="submit"
										value="Login"
										class="button green"
										id="loginButton"
										v-show="!loading"
										v-cloak />
								</div>
								<div class="login-box-row buttons">
									<input type="checkbox" id="rememberMe" name="rememberMe" v-model="rememberMe" />
									<label for="rememberMe">Remember my username</label>
								</div>
								<div class="login-box-row buttons stacked">
									<router-link
										:to="{
											path: '/Recover',
											query: {redirectUrl: redirectUrl},
										}">
										I have forgotten my password
									</router-link>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</div>
			<CitrixLogin />
		</div>
		<div class="incidentBox" v-if="displayMaintenance && maintenanceHeading">
			<span class="alert-icon incident-icon mr-2"></span>
			<div class="incident-body">
				<div class="incidentHeading">
					<span class="align-middle incident-text">{{ maintenanceHeading }}</span>
				</div>
				<div class="incidentTime">
					{{ maintenanceTimeDate }} from {{ maintenanceTimeStart }} to {{ maintenanceTimeEnd }}
				</div>
				<div>
					<a class="incidentUrl" href="https://affinityteam.statuspage.io/" target="_blank"
						>View Latest Update</a
					>
				</div>
			</div>
			<span class="cross-icon incident-icon ml-2" @click="closeIncident()"></span>
		</div>
		<div
			class="incidentBox incidentBox-incident"
			v-else-if="displayIncident && incidentsHeading"
			:class="{
				'incidentBox-incident': incidentInpact === 'minor',
				'incidentBox-incident-major': incidentInpact !== 'minor',
			}">
			<span class="alert-icon incident-icon mr-2"></span>
			<div class="incident-body">
				<div class="incidentHeading">
					<span class="align-middle incident-text">{{ incidentsHeading }}</span>
				</div>
				<div class="incidentTime">
					<!-- {{ this.maintenanceTimeDate }} from {{ this.maintenanceTimeStart }} to {{ this.maintenanceTimeEnd }} -->
					Created at {{ incidentsTimeStart }}
				</div>
				<div>
					<a class="incidentUrl" :href="incidentsshortLink" target="_blank">View Latest Update</a>
				</div>
			</div>
			<span class="cross-icon incident-icon ml-2" @click="closeIncident()"></span>
		</div>
	</div>
</template>

<script>
	import CitrixLogin from "@/components/CitrixLogin.vue";
	import Header from "@/components/Header.vue";
	import Footer from "@/components/Footer.vue";
	import dayjs from "dayjs";
	import {setItemWithExpiry, getItemWithExpiry} from "@/util.js";

	export default {
		name: "Login",
		props: ["errorMsg"],
		components: {
			CitrixLogin,
			Header,
			Footer,
		},
		created() {
			this.populatePage();
		},
		data() {
			return {
				unHelpTooltip: {
					content: "This can be your username, email, or membership number (but not your surname).",
					trigger: "hover focus click",
				},
				error: this.errorMsg || "",
				loading: false,
				availableEmailUsers: null,
				rememberMe: false,
				redirectUrl: "",
				emailUser: null,
				title: "",

				displayMaintenance: true,

				maintenanceHeading: "",
				maintenanceTimeStart: "",
				maintenanceTimeEnd: "",
				maintenanceTimeDate: "",
				displayIncident: true,
				incidentsHeading: "",
				incidentsTimeStart: "",
				incidentsshortLink: "",
				incidentInpact: "",
			};
		},
		computed: {
			showEmailUsers() {
				return this.availableEmailUsers && this.availableEmailUsers.length > 1;
			},
		},
		methods: {
			populatePage() {
				this.loading = true;
				if (
					typeof this.$route.query != "undefined" &&
					typeof this.$route.query.redirectUrl != "undefined"
				) {
					this.redirectUrl = this.$route.query.redirectUrl;
				}
				this.$ajax
					.post("/auth/PopulatePage", {
						RedirectUrl: this.$validUrl(this.redirectUrl) ? this.redirectUrl : "",
					})
					.then(async (resp) => {
						if (typeof resp.data != "undefined") {
							const {
								Error,
								ClientRedirect,
								RedirectUrl: redirectUrl,
								RedirectPage,
								UserName,
								RememberMe,
								EmailUsers,
							} = resp.data;
							if (Error) {
								this.error = resp.data.Error;
							} else if (ClientRedirect && redirectUrl) {
								window.location.href = redirectUrl;
							} else if (RedirectPage != null) {
								var params = {};
								if (RedirectPage == "SetupQuestions" && Questions.length) {
									params = {questions: Questions};
								}
								this.$router.push({
									name: RedirectPage,
									params: params,
									query: {
										redirectUrl,
									},
								});
							}

							if (RememberMe && UserName) {
								this.$refs.userName.value = UserName;
								this.rememberMe = true;
							}

							if (EmailUsers != null && EmailUsers.Users != null) {
								this.availableEmailUsers = EmailUsers.Users;
							}
						}
					})
					.catch(() => {})
					.then(() => {
						this.loading = false;
					});
			},
			checkEmail() {
				let emailInput = this.$refs.userName.value;
				if (emailInput && this.$validateEmail(emailInput)) {
					this.loading = true;
					this.$ajax
						.get("/auth/CheckEmail", {
							params: {
								email: emailInput,
							},
						})
						.then((resp) => {
							if (emailInput == resp.data.EmailAddress) {
								this.availableEmailUsers = resp.data.Users;
							}
						})
						.catch()
						.then(() => {
							this.loading = false;
						});
				}
			},
			handleFormSubmit() {
				let data = {
					redirectUrl: this.$validUrl(this.redirectUrl) ? this.redirectUrl : "",
					userName: this.$refs.userName.value,
					password: this.$refs.password.value,
					rememberMe: this.rememberMe,
					emailUser: this.emailUser,
				};
				this.error = "";
				if (data.userName == "" && data.password == "") {
					this.error = "Username and Password cannot be blank.";
				} else if (data.userName == "") {
					this.error = "Username cannot be blank.";
				} else if (data.password == "") {
					this.error = "Password cannot be blank.";
				}
				if (this.error != "") {
					return;
				}

				this.loading = true;

				this.$ajax
					.post("/auth/login", data)
					.then(async (resp) => {
						if (typeof resp.data != "undefined") {
							const {
								Error,
								ClientRedirect,
								RedirectUrl: redirectUrl,
								RedirectPage,
								Questions,
								EssRedirect,
							} = resp.data;
							if (Error != null) {
								this.error = resp.data.Error;
								this.loading = false;
							} else {
								let channels, mfaNotSet;

								if (!EssRedirect) {
									if (
										["SetupPassword", "Emails"].includes(RedirectPage) < 0 ||
										RedirectPage == null
									) {
										const {authenticated, mfaRequired, otpChannels} = await this.$checkMFACookie(
											redirectUrl,
										);
										mfaNotSet = mfaRequired && !authenticated;
										channels = otpChannels;
									}
								}

								if (ClientRedirect && redirectUrl) {
									if (mfaNotSet) {
										this.$router.push({
											name: "MFA",
											params: {
												redirectUrl,
												otpChannels: channels,
											},
										});
									} else {
										if (!EssRedirect)
											await this.$ajax.post("/legacy/success", null, {
												withCredentials: true,
											});
										window.location.href = redirectUrl;
									}
								} else if (RedirectPage) {
									let params = {
										questions: undefined,
										otpChannels: channels,
										mfaNotSet: mfaNotSet,
									};
									if (RedirectPage == "SetupQuestions" && Questions.length) {
										params.questions = resp.data.Questions;
									}
									const routeConfig = {
										name: RedirectPage,
										params: params,
										query: {
											redirectUrl,
										},
									};

									this.$router.push(routeConfig);
								}
							}
						}
					})
					.catch(() => {
						this.loading = false;
					});
			},
			closeIncident() {
				this.displayIncident = false;
				this.displayMaintenance = false;
				setItemWithExpiry('incidentBox', 'true', 14400000);
			},
			loadIncidentBox() {
				this.$ajax.get("https://jwfkgpbj4kbg.statuspage.io/api/v2/summary.json").then((response) => {
				if (response.data.incidents[0]) {
					this.displayIncident = true;
					this.displayMaintenance = false;
					this.incidentsHeading = response.data.incidents[0].name;
					this.incidentsTimeStart = dayjs(response.data.incidents[0].created_at).format(
						"DD MMM YYYY HH:mm",
					);
					this.incidentsshortLink = response.data.incidents[0].shortlink;
					this.incidentInpact = response.data.incidents[0].impact;
					return;
				}
				if (response.data.scheduled_maintenances && response.data.scheduled_maintenances[0]) {
					this.displayMaintenance = true;
					this.displayIncident = false;
					this.maintenanceHeading = response.data.scheduled_maintenances[0].name;
					this.maintenanceTimeDate = dayjs(
						response.data.scheduled_maintenances[0].scheduled_for,
					).format("DD MMM YYYY");
					this.maintenanceTimeStart = dayjs(
						response.data.scheduled_maintenances[0].scheduled_for,
					).format("HH:mm");
					this.maintenanceTimeEnd = dayjs(
						response.data.scheduled_maintenances[0].scheduled_until,
					).format("HH:mm");
					return;
				}
			});
			}
		},
		mounted() {
			this.title = this.$router.history.current.meta.title;
			
			if (getItemWithExpiry('incidentBox') !== 'true') {
				this.loadIncidentBox();
			} 
		},
	};
</script>
<style>
	.incident-icon {
		display: inline-block;
		width: 18px;
		height: 18px;
		margin-top: 2px;
		margin-right: 10px;
		vertical-align: top;
	}

	.alert-icon {
		vertical-align: top;
		background-image: url("../assets/exclamation-mark-white.svg");
		background-size: cover;
		background-position: center;
	}

	.cross-icon {
		background-image: url("../assets/cross-svg.svg");
		background-size: cover;
		background-position: center;

		cursor: pointer;
	}

	.align-middle {
		vertical-align: middle;
	}

	.incidentBox {
		position: fixed;
		bottom: 60px;
		right: 20px;
		max-width: 320px;
		z-index: 99999;

		color: white;
		padding: 20px;
		background-color: #3498db;

		font-size: 14px;
	}

	.incidentBox-incident {
		background-color: #ff6600;
	}

	.incidentBox-incident-major {
		background-color: #e74c3c;
	}

	.incidentHeading {
		font-size: 14px;
	}
	.incidentTime {
		margin-top: 10px;
	}

	.incident-text {
		display: inline-block;
		max-width: 230px;
	}

	.incident-body {
		display: inline-block;
		vertical-align: top;
	}

	.incidentUrl {
		display: block;
		margin-top: 10px;
		color: white;
		text-decoration: underline !important;
	}
</style>
