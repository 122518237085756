<script>
  import Setup from "./Setup.vue";
  import PasswordPage from "./PasswordPage.vue";
  import {getErrorMessage} from "../remote/ajax";
  //TODO: run a client-side check to see if token and userid is present. If not, just don't display the page. Or error...
  export default {
    name: "SetNew",
    props: ["pageSubtitle", "userId", "redirectUrl"],
    components: {
      PasswordPage,
    },
    data() {
      return {
        error: "",
        loading: false,
        token: "",
        success: "",
        output: {
          password: "",
          confirmPassword: "",
        },
      };
    },
    components: {
      Setup,
    },
    methods: {
      handleFormSubmit() {
        let data = {
          Password: this.output.password,
          ConfirmPassword: this.output.confirmPassword,
          UserId: this.userId,
        };

        this.error = "";
        if (data.Password == "" && data.ConfirmPassword == "") {
          this.error = "Password fields cannot be blank.";
        } else if (data.ConfirmPassword == "") {
          this.error = "Confirm Password field cannot be blank.";
        } else if (data.Password == "") {
          this.error = "Password field cannot be blank.";
        } else if (data.Password != data.ConfirmPassword) {
          this.error = "Password fields do not match.";
        }
        if (this.error != "") return;

        this.loading = true;
        const __this = this;

        this.$ajax
          .post("/Password/SetNew", data, {
            headers: {
              Authorization: this.token,
            },
          })
          .then((resp) => {
            if (typeof resp.data != "undefined") {
              if (resp.data.result === "FAILED") {
                this.error = resp.data.message;
                this.loading = false;
              } else {
                this.success =
                  "Password reset successful. Redirecting you to the login screen...";
                setTimeout(() => {
                  __this.$router.push({
                    name: "Login",
                    query: this.redirectUrl
                      ? {redirectUrl: this.redirectUrl}
                      : undefined,
                  });
                }, 5000);
              }
            }
          })
          .catch((error) => {
            if (error.result === "UNAUTHORIZED" || error.status === 401) {
              this.redirectTo(
                "The browser session has expired. Redirecting you to the Reset Pasword page...",
              );
            } else {
              this.loading = false;
              this.error =
                "This password reset has timed out. Go back to the login page and click I have forgotten my password.";
            }
          });
      },
      verifyToken(token) {
        this.$ajax
          .get("/Token/Validate", {
            headers: {
              Authorization: this.token,
            },
          })
          .catch((res) => {
            const {Valid} = res;
            if (!Valid) {
              this.redirectTo(
                "The browser session has expired. Redirecting you to the Reset Pasword page...",
              );
            }
          });
      },
      redirectTo(message, page) {
        const router = this.$router;
        const name = page || "Recover";
        if (message) this.error = message;
        setTimeout(() => {
          router.push({
            name,
            query: {redirectUrl: this.redirectUrl || undefined},
          });
        }, 3000);
      },
    },
    mounted() {
      this.token = this.$route.query.token || "";
      if (this.token && !this.pageLoaded) {
        this.verifyToken(this.token);
      }
    },
  };
</script>

<template>
  <setup title="Reset Password" :error="error" :success="success">
    <form class="card-body" method="post" @submit.prevent="handleFormSubmit">
      <h3>{{ pageSubtitle }}</h3>
      <passwordPage :loading="loading" v-model="output"></passwordPage>
    </form>
  </setup>
</template>
