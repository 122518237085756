<template>
	<setup title="Confirm Your Identity" :error="error" :hidePageTitle="hidePageTitle">
		<div class="otp-sending-progress" v-cloak v-if="sendingOtp">
			<div>
				<span>Sending your code</span>
			</div>
		</div>
		<div class="otp-sending-progress" v-cloak v-if="verifyingOtp">
			<div>
				Checking that code.
				<span style="white-space: nowrap">Please wait a moment...</span>
			</div>
		</div>
		<div v-if="verifyingStatus">
			Verifying token...<span style="white-space: nowrap">Please wait</span>
		</div>
		<form
			class="card-body"
			method="post"
			@submit.prevent="handleFormSubmit"
			novalidate
			v-if="!verifyingStatus">
			<div class="otp-input-group" v-if="showOtpInput">
				<p>
					<strong>Enter your verification code here then click to verify:</strong>
				</p>

				<div class="form-row">
					<div class="otp-control">
						<v-otp-input
							ref="otpInput"
							input-classes="otp-input"
							separator=""
							:num-inputs="6"
							:should-auto-focus="true"
							:is-input-num="false"
							@on-change="handleOtpChange"
							@on-complete="handleOtpComplete" />
					</div>
				</div>
				<div class="form-row">
					<div class="spinner small light" v-show="verifyingOtp"></div>
					<input
						type="submit"
						value="VERIFY"
						class="button large blue"
						v-show="!verifyingOtp"
						v-cloak
						ref="submitBtn" />
				</div>
			</div>
			<div class="default-form w-border" style="text-align: center; margin: auto">
				<p v-if="!showOtpInput">Where would you like us to send your verification code?<br /></p>
				<p v-if="showOtpInput">Need a new verification code?<br /></p>
				<div class="otp-block-options">
					<div class="otp-option" v-for="list in otpChannels" v-bind:key="list.channel">
						<input
							type="radio"
							v-on:change="handleContactChange(list.channel)"
							:value="list.channel"
							:name="list.channel"
							:id="list.channel"
							v-bind:checked="list.channel == selectedChannel" />
						<label :for="list.channel"
							>Send it to my {{ `${getOptionLabel(list.channel)}` }}
							<span>{{ `(${list.value})` }}</span></label
						>
					</div>
				</div>
				<div class="otp-block-button">
					<button
						type="button"
						class="button small green"
						v-cloak
						ref="btnSendOTP"
						v-on:click="sendOtp"
						v-bind:disabled="otpSending">
						<span v-show="!otpSending">{{ showOtpInput ? "RESEND" : "SEND" }}</span>
						<span class="spinner small light" v-show="otpSending"></span>
					</button>
				</div>
			</div>
		</form>
	</setup>
</template>

<style scoped>
	.card-body p {
		font-size: 14px;
		font-weight: bold;
	}
	.otp-block-options {
		text-align: left;
		max-width: 360px;
		margin: 0 auto 12px;
	}

	.otp-option {
		position: relative;
		padding-left: 24px;
	}

	.otp-option > * {
		vertical-align: middle;
		line-height: 24px;
		font-size: 12px;
	}
	.otp-option input {
		position: absolute;
		left: 0;
		top: 5px;
		height: 13px;
		width: 13px;
		margin: 0 8px 0 0;
	}
	@media screen and (min-width: 560px) {
		.otp-option label {
			white-space: nowrap;
		}
	}
	@media screen and (max-width: 559px) {
		.otp-option span {
			white-space: nowrap;
		}
	}

	.otp-block-option {
		margin-bottom: 4px;
	}

	.otp-block-button {
		margin-bottom: 20px;
	}
	.otp-block-button > button {
		width: 75px;
		height: 32px !important;
	}

	.otp-block-button > button:disabled {
		background-color: transparent !important;
		border-color: transparent !important;
	}

	.otp-input-group {
		margin-bottom: 32px;
	}
</style>

<script>
	import Setup from "./Setup.vue";
	export default {
		name: "MFA",
		props: ["redirectUrl", "otpChannels"],
		data() {
			return {
				sendingOtp: false,
				verifyingOtp: false,
				verifyingStatus: false,
				otpSending: false,
				selectedChannel: "",
				showOtpInput: false,
				retryCount: 0,
				code: "",
				token: "",
				error: "",
				hidePageTitle: false,
				page: undefined,
				failUrl: "",
			};
		},
		methods: {
			getOptionLabel(channel) {
				switch (channel) {
					case "sms":
						return "mobile";
					case "work-email":
						return "work email";
					case "home-email":
						return "personal email";
				}
			},
			async checkOtpStatus(token, channel, redirectUrl, page = undefined) {
				this.verifyingStatus = true;

				const resp = await this.$ajax
					.get(`${this.$apiUrl}/api/Otp/status`, {
						params: {
							token,
							page,
						},
						withCredentials: true,
					})
					.catch((res) => {
						if (res.status == 401) {
							this.redirectToLogin("Session has expired. Redirect you in Login page...");
						}
					})
					.finally(() => {
						this.verifyingStatus = false;
					});

				if (resp != undefined) {
					const {otpChannels} = await this.$checkMFACookie(redirectUrl);
					this.otpChannels = otpChannels;
					this.redirectUrl = redirectUrl;
					this.selectedChannel = channel;
					this.showOtpInput = true;
					this.token = token;

					switch (resp.data.status) {
						case "expired":
							this.error = "The OTP that was sent is already expired. Please get another OTP code.";
							break;
						case "invalid-token":
							this.error = "OTP code is already expired. Please request a new one.";
							break;
					}
				}
			},
			redirectToLogin(message) {
				const router = this.$router;
				this.error = message;
				setTimeout(() => {
					router.push({
						name: "Login",
						query: {redirectUrl: this.redirectUrl || undefined},
					});
				}, 3000);
			},
			async sendOtp() {
				if (!this.selectedChannel) {
					this.error = `No contact has been selected.`;
					return false;
				}
				this.error = "";
				const body = {
					channel: this.selectedChannel,
					token: this.token || undefined,
					page: this.page,
				};
				this.otpSending = true;
				this.retryCount = 0;
				this.$ajax
					.post(`${this.$apiUrl}/api/Otp`, body, {
						withCredentials: true,
					})
					.then((resp) => {
						const {isSuccess, token, status} = resp.data;
						this.otpSending = false;
						if (isSuccess || status === "pending") {
							this.showOtpInput = true;
							this.token = token;
							this.$router.push({
								name: "MFA",
								params: {
									redirectUrl: this.redirectUrl,
									otpChannels: this.otpChannels,
								},
								query: {
									token,
									channel: this.selectedChannel,
									redirectUrl: this.redirectUrl,
								},
							});
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.redirectToLogin(
								"The browser session has expired. Redirecting you to the login page...",
							);
						}
					});
			},
			handleContactChange(channel) {
				const {channel: qChannel} = this.$route.query;
				if (channel != this.selectedChannel) {
					this.selectedChannel = channel;
					this.showOtpInput = false;
				}

				if (channel == qChannel) {
					this.selectedChannel = channel;
					this.showOtpInput = true;
				}
			},
			handleFormSubmit() {
				if (!this.code || this.code.length < 6) {
					this.error = "Please enter your verification code.";
					return false;
				}

				this.error = "";
				this.verifyingOtp = true;
				this.$ajax
					.post(
						`${this.$apiUrl}/api/Otp/verify`,
						{code: this.code, token: this.token, page: this.page},
						{withCredentials: true},
					)
					.then(async (resp) => {
						const {isSuccess, status} = resp.data;
						if (this.page) {
							const redirectUrl = isSuccess ? this.page : this.failUrl;
							if (!isSuccess) {
								this.verifyingOtp = false;
								this.handleErrorResponse(status);
								if (["unknown", "expired", "invalid-token"].includes(status)) {
									setTimeout(() => {
										window.location.href = this.failUrl;
									}, 2000);
								}
							} else {
								window.location.href = redirectUrl;
							}
						} else {
							if (isSuccess) {
								this.verifyingOtp = true;
								await this.$ajax.post("/legacy/success", null, {
									withCredentials: true,
								});
								window.location.href = this.redirectUrl;
							} else {
								this.verifyingOtp = false;
								this.handleErrorResponse(status);
								this.$refs.otpInput.clearInput();
							}
						}
					})
					.catch((err) => {
						if (err.status == 401) {
							this.redirectToLogin(
								"That verification code has expired. Redirecting you to the login page...",
							);
						} else {
							this.handleErrorResponse(err.status);
						}
					});
			},
			handleErrorResponse(status) {
				switch (status) {
					case "unknown": // Error on twilio side
						this.error = "Something went wrong, please enter it again later.";
						break;
					case "pending": // User inputted invalid OTP
						this.retryCount += 1;
						this.error =
							"That code is incorrect. You have 6 attempts at entering a valid code. Please try again.";
						break;
					case "expired": // OTP is expired
						this.error = this.page
							? "That verification code has expired. Redirecting you to the previous page..."
							: "OTP code is already expired. Please request a new one.";
						break;
					case "invalid-token": // OTP is expired
						this.error = this.page
							? "That verification code has expired. Redirecting you to the previous page..."
							: "OTP code is already expired. Please request a new one.";
						break;
					case "max-attempt": // Maximum attemp was reached
						this.error =
							"You only have 6 attempts at entering a valid code. Click RESEND to request a new code.";
						break;
				}
			},
			handleOtpChange(value) {
				this.code = value;
			},
			handleOtpComplete(value) {
				this.code = value;
				setTimeout(() => {
					this.$refs.submitBtn.focus();
				}, 10);
			},
		},
		components: {
			Setup,
		},
		async mounted() {
			const {redirectUrl, token, channel, page, mfaFailRedirectUrl} = this.$route.query;
			if (token) {
				this.checkOtpStatus(token, channel, redirectUrl, page);
			} else if (page) {
				const {otpChannels, authenticated} = await this.$checkMFACookie(undefined, page);
				this.failUrl = mfaFailRedirectUrl;
				this.otpChannels = otpChannels;
				this.redirectUrl = redirectUrl;
				this.page = page;
				if (authenticated) {
					window.location.href = page;
				}
			} else if (!this.otpChannels && !token) {
				this.$router.push({
					name: "Login",
					query: {redirectUrl: this.redirectUrl || undefined},
				});
			}
			if (this.otpChannels.length) {
				this.selectedChannel =
					this.otpChannels.length == 1 ? this.otpChannels[0].channel || "" : "";
			}
		},
	};
</script>
